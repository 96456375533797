import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'
import ViewPortAnimatedFingerprint from 'components/elements/FingerPrint'
import { useLocation } from 'react-use'

const FormWrapper = styled.div`
  background-color: ${(props) => props.theme.color.light};
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.35);
  padding: 70px 70px 70px 70px;
  margin-top: -140px;

  .contact-form {
    & .form-group > label {
      color: ${({ theme }) => theme.color.secondary} !important;
    }
  }

  .match-form {
  }

  & a {
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
    & img:hover {
      opacity: 0.82;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 30px 30px 70px 30px;
  }
`

const SectionWithBgColor = styled.section`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );

  color: ${({ theme }) => theme.color.light};
`

const MatchWrapper = styled.section`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: #fff;
  }
`

const SectionMatch = styled.section`
  position: relative;
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  color: ${({ theme }) => theme.color.light};
  z-index: 2;
  border-radius: 32px;

  @media (min-width: 1200px) {
    margin: -5rem 18rem 0 18rem;
  }

  @media (max-width: 1199.98px) {
    margin: -3rem 8rem 0 8rem;
  }

  @media (max-width: 991.98px) {
    margin: -3rem 3rem 0 3rem;
  }

  @media (max-width: 575.98px) {
    margin: 0;
  }
`

const Content = styled(ParseContent)`
  table {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: contain !important;
    }

    .social-button {
      display: inline-flex !important;
      width: 35px;
      height: 35px;
    }

    a {
      img {
      }
    }
  }

  p {
    line-height: 22px;
  }

  @media screen and (max-width: 991px) {
    tr {
      display: flex !important;
      flex-wrap: wrap !important;
      height: unset !important;
    }

    td {
      height: unset !important;
      width: 100% !important;
      padding-bottom: 10px;

      &:last-of-type {
        pointer-events: none;
        display: none;
      }
    }
  }
`

const MatchContent = styled(ParseContent)`
  @media (min-width: 992px) {
    padding: 0 3rem;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 50px;
      margin-bottom: 2rem;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 30px;
    margin-bottom: 2rem;
  }
`

const SVGWrapper = styled.div`
  bottom: -7rem;
  right: -20rem;
  opacity: 0.1;
`

interface FormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const Form: React.FC<FormProps> = ({ fields }) => {
  const [changeForm, setChangeForm] = useState(5)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.hash && location.hash === '#solliciteer') {
      setChangeForm(3)
    }

    if (location.hash && location.hash === '#confirmed') {
      setIsConfirmed(true)
    }
  }, [])

  return (
    <>
      {fields.viewtype === 'contact' && (
        <section className="mb-5">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-12">
                <FormWrapper>
                  <div className="pb-lg-0 pb-4">
                    <Content content={fields.description} />
                  </div>
                  <GravityForm id={1} className="contact-form" />
                </FormWrapper>
              </div>
            </div>
          </div>
        </section>
      )}
      {fields.viewtype === 'newsletter' && (
        <SectionWithBgColor className="mb-5">
          <div className="container py-4">
            <div className="justify-content-center">
              <div className="col-12">
                <ParseContent content={fields.description} />
                <div className="pt-4 px-lg-0 px-2">
                  <GravityForm id={2} className="nieuwsbrief-form" />
                </div>
              </div>
            </div>
          </div>
        </SectionWithBgColor>
      )}
      {fields.viewtype === 'match' && (
        <MatchWrapper id="match">
          <SectionMatch className="mb-5">
            <SVGWrapper className="position-absolute">
              <ViewPortAnimatedFingerprint />
            </SVGWrapper>
            <div className="container py-4">
              <div className="row justify-content-center">
                <div className="col-12">
                  <MatchContent content={fields.title} />
                  {isConfirmed ? (
                    <div>Test</div>
                  ) : (
                    <>
                      <MatchContent content={fields.description} />
                      <div className="pt-4" id="solliciteer-direct">
                        {changeForm === 5 ? (
                          <>
                            <GravityForm
                              key="matchform"
                              id={5}
                              className="match-form"
                            />
                            <div className="flex justify-content-center text-center mt-4">
                              <button
                                type="button"
                                className="text-white"
                                style={{ textDecoration: 'underline' }}
                                onClick={() => setChangeForm(3)}
                              >
                                Al gematcht? Sla over
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="px-lg-5">
                            <GravityForm key="sollictationform" id={3} />
                            <div className="flex justify-content-center text-center mt-4">
                              <button
                                type="button"
                                className="text-white"
                                style={{ textDecoration: 'underline' }}
                                onClick={() => setChangeForm(5)}
                              >
                                Ontdek hier of jij bij e-office past!
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </SectionMatch>
        </MatchWrapper>
      )}
    </>
  )
}

export default Form
